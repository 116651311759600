import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { RESERVATION_RESPONSE_INTERNAL_ERROR, RESERVATION_RESPONSE_SUCCESS } from '../../../consts';
import { Product, Shop } from '../../interfaces/shop.interface';
import { Reservation, ShoppingCart, ShoppingCartReservation, WinProduct } from '../../interfaces/shopping-cart.interface';
import { ShopApiService } from '../../services/shop-api.service';
import { ShopService } from '../../services/shop.service';
import { filter, Observable, tap } from 'rxjs';
import { OrderSummaryService } from '../../services/order-summary.service';
import { Location, isPlatformBrowser, PlatformLocation, isPlatformServer } from '@angular/common';
import { LoggedDialogComponent } from '../../components/dialogs/logged-dialog/logged-dialog.component';
import { TranslocoService } from '@ngneat/transloco';
import { UniversalDeviceDetectorService } from '../../services/universal-device-detector.service';
import { GaService } from '../../services/ga.service';
import { DeviceInfo } from 'ngx-device-detector';
import { SessionRequest } from '../../interfaces/user.interface';
import * as Sentry from '@sentry/angular';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss'],
})
export class TicketsComponent implements OnInit {
  tickets: Product[] = [];
  nameShop: string;
  shoppingCart$: Observable<ShoppingCart>;
  color: string;
  shop: Shop;

  busy: boolean;
  assetsDir = '/';
  addedToBasket = false;
  winProduct: WinProduct;
  transaction: string;
  loading = true;
  constructor(
    private shopService: ShopService,
    private shopApiService: ShopApiService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    public dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: object,
    private orderSummaryService: OrderSummaryService,
    private platformLocation: PlatformLocation,
    private deviceService: UniversalDeviceDetectorService,
    private translateService: TranslocoService,
    private gaService: GaService
  ) {}

  ngOnInit(): void {
    this.orderSummaryService.options = {
      nextStep: 'details',
      nextButton: true,
      nextButtonDisabled: false,
    };
    this.gaService.event({ action: 'view_product', nonInteraction: true });
    if (environment.production) {
      this.assetsDir = environment.deploy_url;
    }
    this.shop = this.shopService.shop;
    this.tickets = this.shop?.products;
    this.setCanonicalLink();

    this.route.queryParams.subscribe((params) => {
      const transaction = params['transaction'];
      if (transaction) {
        const errCb = () => this.router.navigate(['../../'], { relativeTo: this.route });
        this.shoppingCart$ = this.shopService.initShoppingCart(transaction, errCb).pipe(
          filter((val) => !!val),
          tap((cart) => {
            if (cart.status !== 'active' || cart.shop_uuid !== this.shop.uuid) {
              this.router.navigate(['../../'], { relativeTo: this.route });
            }
          })
        );
      } else {
        this.shopService.shoppingCart = null;
      }
    });

    this.route.params.subscribe((params) => {
      this.nameShop = params['name'];
    });
    this.sendSessionData(() => {
      this.loading = false;
    });
  }

  handleAddTicket(reservation: Reservation, callback) {
    if (!this.addedToBasket) {
      this.gaService.event({ action: 'add_to_basket' });
      this.addedToBasket = true;
    }

    this.busy = true;
    this.makeReservation(reservation, callback);
  }

  makeReservation(reservation: Reservation, callback: any) {
    const cartExpiredBefore = this.shopService.shoppingCart?.status === 'expired';
    if (this.transaction && !cartExpiredBefore) {
      reservation.shopping_cart_uuid = this.transaction;
    }

    if (this.shopService.serverSessionUuid) {
      reservation.shop_session_uuid = this.shopService.serverSessionUuid;
    }
    const source = this.shopService.source;
    const campaign = this.shopService.campaign;
    this.shopApiService.postReservation(this.shop.uuid, reservation, source, campaign).subscribe(
      (response) => {
        const shoppingCart = response.shopping_cart;
        this.shopService.shoppingCart = shoppingCart;
        this.updateUrl(shoppingCart.uuid);
        this.busy = false;
        callback(RESERVATION_RESPONSE_SUCCESS);
      },
      (error) => {
        if ('error' in error.error) {
          this.busy = false;
          callback(error.error.error);
        } else {
          this.busy = false;
          callback(RESERVATION_RESPONSE_INTERNAL_ERROR);
        }
      }
    );
  }

  deleteReservation(productUuid: string, callback) {
    this.busy = true;
    const oldestReservation = this.getOldestReservation(productUuid, this.shopService.shoppingCart);
    this.shopApiService.deleteReservation(this.shopService.shoppingCart.uuid, oldestReservation.uuid).subscribe(
      (response) => {
        this.shopService.shoppingCart = response.shopping_cart;
        this.busy = false;
        callback('success');
      },
      (error) => {
        this.busy = false;
        callback(error.status);
      }
    );
  }

  getOldestReservation(productUuid: string, shoppingCart: ShoppingCart): ShoppingCartReservation {
    if (shoppingCart) {
      const oldestScr = shoppingCart.summary.reservations
        .filter((obj) => obj.product_uuid === productUuid)
        .reduce(function (r, a) {
          return r.created_at < a.created_at ? r : a;
        });
      return oldestScr;
    } else {
      return null;
    }
  }

  checkNumber(productUuid: string): number {
    if (this.shopService.shoppingCart) {
      if (this.shopService.shoppingCart.summary.products) {
        const product = this.shopService.shoppingCart.summary.products.find((obj) => obj.uuid === productUuid);
        return product ? product.quantity : 0;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  updateUrl(uuid: string) {
    const urlTree = this.router.parseUrl(this.router.url);
    const urlWithoutParams = urlTree.root.children['primary'].segments.map((it) => it.path).join('/');

    const newParams = {
      transaction: uuid,
      source: this.shopService.source,
      campaign: this.shopService.campaign,
    };

    this.route.queryParams.subscribe(() => {
      this.location.go(
        this.router
          .createUrlTree([urlWithoutParams], {
            queryParams: newParams,
          })
          .toString()
      );
    });
  }

  sendSessionData(callback) {
    if (isPlatformBrowser(this.platformId)) {
      const deviceInfo: DeviceInfo = this.getDeviceInfo();
      if (!deviceInfo) {
        Sentry.captureMessage('Missing device info in sendSessionData()');
      }
      const sessionRequest: SessionRequest = {
        frontend_session_id: 'Unknown',
        shop_uuid: this.shopService.shop.uuid,
        source: this.shopService.source,
        campaign: this.shopService.campaign,
        platform: 'Unknown',
        browser: 'Unknown',
        language: 'Unknown',
        ip_address: 'Unknown',
        device: deviceInfo.device,
        device_type: deviceInfo.deviceType,
        device_os: deviceInfo.os,
        device_os_version: deviceInfo.os,
        device_user_agent: deviceInfo.userAgent,
        device_browser: deviceInfo.browser,
        device_browser_version: deviceInfo.browser_version,
      };
      this.shopApiService.postSession(sessionRequest).subscribe(
        (response) => {
          this.shopService.serverSessionUuid = response.uuid;
          callback(true);
        },
        (error) => {
          Sentry.captureException(error.error || error, {
            tags: {
              module: 'tickets',
              feature: 'sessionData',
            },
            extra: { ...sessionRequest },
          });
          callback(true);
        }
      );
    } else {
      callback(true);
    }
  }

  openDialog(first_name: string, last_name: string, callback?): void {
    const dialogRef = this.dialog.open(LoggedDialogComponent, {
      width: '600px',
      data: { first_name: first_name, last_name: last_name },
    });

    dialogRef.afterClosed().subscribe((result) => {
      callback(result);
    });
  }

  setCanonicalLink() {
    if (isPlatformBrowser(this.platformId)) {
      const link = document.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', document.location.href.split('?')[0]);
      document.head.appendChild(link);
    }
  }

  nextStepClicked() {
    this.gaService.event({ action: 'begin_checkout' });
  }

  get areAllProductsSoldOut() {
    return !this.tickets.some((ticket) => !ticket.is_sold_out);
  }

  getDeviceInfo(): DeviceInfo {
    const device = this.deviceService.getDeviceInfo();
    const deviceType = this.deviceService.isMobile()
      ? 'mobile'
      : this.deviceService.isTablet()
        ? 'tablet'
        : this.deviceService.isDesktop()
          ? 'desktop'
          : 'Unknown';

    return {
      ...device,
      deviceType,
    };
  }
}
